import { addons } from '@storybook/manager-api';
import { create } from "@storybook/theming/create";
import logo from "../public/static/icons/lockupHorizontal-brandRed.png"

const theme = create({
    base: 'light',
    brandTitle: 'LiveHeats Storybook',
    brandUrl: 'https://liveheats.com',
    brandImage: logo,
    brandTarget: '_self',
});

addons.setConfig({ theme });
